import React from "react";

function CustomModal({ children, set }) {
  return (
    <div className="px-10 py-10 min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover">
      <div
        onClick={() => set(false)}
        className="absolute bg-black opacity-80 inset-0 z-0"
      ></div>
      <div className=" max-w-full max-h-full md:max-w-max  p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white  overflow-auto">
        <div className="grid grid-cols-1 items-center justify-center justify-items-center">
          {" "}
          <div className="w-full max-h-full ">{children}</div>
          <div className="p-3  mt-2 text-center space-x-4 md:block">
            <button
              className="mb-2 md:mb-0 bg-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider  text-white rounded-full hover:shadow-lg hover:bg-red-400 outline-none"
              onClick={() => set(false)}
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomModal;
