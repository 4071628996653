import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../components/Loader";
import Table from "./../components/Table";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import ReactPaginate from "react-paginate";
import PageTitle from "components/PageTitle";
import { getToken } from "auth";
import ItemTitle from "components/ItemTitle";
import CustomModal from "components/CustomModal";
import Docs from "./../components/Docs";

export default function BusinessesRequest({ setRefresh }) {
  const action = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 text-green-500 cursor-pointer"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
  const [businesses, setBusinesses] = useState([]);

  const [load, setload] = useState(true);
  const [showDocs, setShowDocs] = useState(false);
  const [docs, setDocs] = useState({
    cardIdFileLink: "",
    commercialRegisterFileLink: "",
    nifFileLink: "",
  });
  const [paginateConfig, setPaginateConfig] = useState({
    perPage: 5,
    page: 0,
    pages: 0,
  });

  const handlePageClick = (event) => {
    let page = event.selected;
    console.log(page);
    setPaginateConfig({ ...paginateConfig, page });
  };
  const { page, perPage, pages } = paginateConfig;
  let items = businesses.slice(page * perPage, (page + 1) * perPage);

  //load data from the server
  useEffect(() => {
    axios
      .get(`/businesses?validated=false`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        const data = res.data;

        setBusinesses(data);
        setPaginateConfig({
          ...paginateConfig,
          pages: Math.ceil(data.length / paginateConfig.perPage),
        });
        setload(false);
        setRefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //handle activate bisuness account
  const handleAction = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="flex flex-col bg-white p-10 border-2 rounded-lg">
            <h1 className="text-xl">Êtes-vous sûr ?</h1>
            <p>Vous souhaitez activer ce compte d’entreprise ?</p>
            <div className="flex gap-3 mt-5">
              <button
                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-full"
                onClick={onClose}
              >
                Non
              </button>
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
                onClick={() => {
                  handleActivate(id);
                  onClose();
                }}
              >
                Oui
              </button>
            </div>
          </div>
        );
      },
    });
  };
  //handle activate business
  const handleActivate = (id) => {
    axios
      .patch(
        `/businesses/${id}`,
        { validated: true },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((res, err) => {
        if (!err) alert("Busniss Account is activated");
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return load ? (
    <Loader />
  ) : (
    <div className="p-10">
      <PageTitle>Demandes des entreprises</PageTitle>
      {showDocs && (
        <CustomModal set={setShowDocs}>
          <Docs
            cardIdFileLink={docs?.cardIdFileLink}
            commercialRegisterFileLink={docs?.commercialRegisterFileLink}
            nifFileLink={docs?.nifFileLink}
          />
        </CustomModal>
      )}
      <div className="mt-5 mb-3">
        <ItemTitle title={"Nombre des demandes :"} value={businesses?.length} />
      </div>
      <Table
        data={items}
        validated={false}
        action={action}
        handleAction={handleAction}
        setShowDocs={setShowDocs}
        setDocs={setDocs}
      />
      <ReactPaginate
        previousLabel={"précédent"}
        nextLabel={"suivant"}
        pageCount={pages}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </div>
  );
}
