import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../components/Loader";
import Table from "components/Table";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import ReactPaginate from "react-paginate";
import { getToken } from "auth";
import ItemTitle from "components/ItemTitle";
import PageTitle from "components/PageTitle";
import CustomModal from "components/CustomModal";
import Docs from "./../components/Docs";
export default function ValidatedBusinesses() {
  const action = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 text-yellow-500 cursor-pointer"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
  const [businesses, setBusinesses] = useState([]);
  const [load, setload] = useState(true);
  const [paginateConfig, setPaginateConfig] = useState({
    perPage: 5,
    page: 0,
    pages: 0,
  });
  const [showDocs, setShowDocs] = useState(false);
  const [docs, setDocs] = useState({
    cardIdFileLink: "",
    commercialRegisterFileLink: "",
    nifFileLink: "",
  });
  const handlePageClick = (event) => {
    let page = event.selected;
    console.log(page);
    setPaginateConfig({ ...paginateConfig, page });
  };
  const { page, perPage, pages } = paginateConfig;
  let items = businesses.slice(page * perPage, (page + 1) * perPage);
  //load data from the server
  useEffect(() => {
    axios
      .get(`/businesses/?validated=true `, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        const data = res.data;

        setBusinesses(data);
        setPaginateConfig({
          ...paginateConfig,
          pages: Math.ceil(data.length / paginateConfig.perPage),
        });
        setload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //handle desactivate bisuness account
  const handleAction = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="flex flex-col bg-white p-10 border-2 rounded-lg">
            <h1 className="text-xl">Êtes-vous sûr ?</h1>
            <p>Vous souhaitez désactiver ce compte d’entreprise ?</p>
            <div className="flex gap-3 mt-5">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
                onClick={onClose}
              >
                Non
              </button>
              <button
                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-full"
                onClick={() => {
                  handleDesactivate(id);
                  onClose();
                }}
              >
                Oui
              </button>
            </div>
          </div>
        );
      },
    });
  };
  const handleDesactivate = (id) => {
    axios
      .patch(
        `/businesses/${id}`,
        { validated: false },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((res, err) => {
        if (!err) alert("Busniss Account is desactivated");
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return load ? (
    <Loader />
  ) : (
    <div className="p-10">
      <PageTitle>Gestion des entreprises</PageTitle>
      {showDocs && (
        <CustomModal set={setShowDocs}>
          <Docs
            cardIdFileLink={docs?.cardIdFileLink}
            commercialRegisterFileLink={docs?.commercialRegisterFileLink}
            nifFileLink={docs?.nifFileLink}
          />
        </CustomModal>
      )}
      <div className="mt-5 mb-3">
        <ItemTitle
          title={"Nombre Des Entreprises :"}
          value={businesses?.length}
        />
      </div>
      <Table
        data={items}
        validated={true}
        action={action}
        handleAction={handleAction}
        showDocs={showDocs}
        setShowDocs={setShowDocs}
        setDocs={setDocs}
      />
      <ReactPaginate
        previousLabel={"précédent"}
        nextLabel={"suivant"}
        pageCount={pages}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </div>
  );
}
