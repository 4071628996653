import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

function ClientsTable({ data = [] }) {
  const [paginateConfig, setPaginateConfig] = useState({
    perPage: 5,
    page: 0,
    pages: 0,
  });
  useEffect(() => {
    setPaginateConfig({
      ...paginateConfig,
      pages: Math.ceil(data.length / paginateConfig.perPage),
    });
  }, []);
  const handlePageClick = (event) => {
    let page = event.selected;
    console.log(page);
    setPaginateConfig({ ...paginateConfig, page });
  };
  const { page, perPage, pages } = paginateConfig;
  let items = data.slice(page * perPage, (page + 1) * perPage);
  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      N° DE TÉLÉPHONE
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {items.map((client) => (
                    <tr key={client.data.email + client.data.phoneNumber}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {client.data.fullName}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {client.data.email}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {client.data.phoneNumber}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ReactPaginate
        previousLabel={"précédent"}
        nextLabel={"suivant"}
        pageCount={pages}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </>
  );
}

export default ClientsTable;
