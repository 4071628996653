import React from "react";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = ({ ordersStatus }) => {
  const data = {
    labels: ["Panier sauvé", "Accepté", "Annulé", "En attente", "Refusée"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          ordersStatus?.pickedUpOrders || 0,
          ordersStatus?.acceptedOrders || 0,
          ordersStatus?.clientCanceledOrders || 0,
          ordersStatus?.pendingOrders || 0,
          ordersStatus?.refusedOrders || 0,
        ],
        backgroundColor: [
          "rgba(51, 153, 255, 0.8)",
          "rgba(6, 85, 53, 0.8)",
          "rgba(218, 165, 32, 0.8)",
          "rgba(186, 218, 85, 0.8)",
          "rgba(255, 64, 64, 0.8)",
        ],
        borderColor: [
          "rgba(51, 153, 255, 1)",
          "rgba(6, 85, 53, 1)",
          "rgba(218, 165, 32, 0.8)",
          "rgba(186, 218, 85, 1)",
          "rgba(255, 64, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="flex  flex-col bg-white p-5 rounded-lg  items-center justify-center ">
      <h3 className="text-lg text-gray-800">Statistiques des commandes </h3>
      <div className=" ">
        <Doughnut data={data} />
      </div>
    </div>
  );
};

export default DoughnutChart;
