import { getMonth } from "helper";
import { sum } from "helper";
import React from "react";
import _ from "underscore";
import ItemTitle from "components/ItemTitle";
function MonthlyProfit({ data, percentage = 0.2, showData = false }) {
  return (
    <div className="mt-5">
      <h1 className=" text-2xl  mb-5">Gains par mois</h1>
      {showData ? (
        <div className="  bg-white rounded-lg flex flex-col gap-4 p-5">
          {Object.keys(data).map(function (year) {
            return (
              <div className="bg-gray-50 rounded-lg p-3 flex flex-col gap-3 ">
                <div>
                  {" "}
                  <span className="px-2 py-1  ml-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    {year}
                  </span>
                </div>
                <div className=" flex flex-col gap-3 ">
                  {Object.keys(data[year]).map(function (month) {
                    return (
                      <div className="flex items-center gap-4   w-full">
                        <div>
                          <span className="px-2 py-1  ml-2 inline-flex text-xs leading-5 font-bold rounded-full bg-blue-100 text-blue-800">
                            {getMonth(month)}
                          </span>
                        </div>

                        <div>
                          <ItemTitle
                            title="Gains total de restaurant :"
                            value={`${sum(data[year][month])} DA`}
                          />
                        </div>
                        <div>
                          <ItemTitle
                            title="Votre gains :"
                            value={`${sum(data[year][month]) * percentage} DA`}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <h2 className="text-center text-2xl ">Pas des gains</h2>
      )}
    </div>
  );
}

export default MonthlyProfit;
