import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../components/Loader";
import ClientsTable from "./../components/ClientsTable";

import PageTitle from "./../components/PageTitle";
import { getToken } from "auth";
import ItemTitle from "components/ItemTitle";
export default function ClientsManager() {
  const [clients, setClients] = useState([]);
  const [load, setload] = useState(true);

  //load data from the server
  useEffect(() => {
    axios
      .get(`/clients/ `, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        const data = res.data;

        setClients(data);

        setload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return load ? (
    <Loader />
  ) : (
    <div className="p-10">
      <PageTitle>Liste des clients</PageTitle>
      <div className="mt-5 mb-3">
        <ItemTitle title={"Nombre Des clients :"} value={clients?.length} />
      </div>
      <ClientsTable data={clients} />
    </div>
  );
}
