import axios from "axios";
import { useState } from "react";

const Login = ({ history }) => {
  const [error, setError] = useState();
  const handleFormSubmit = (e) => {
    e.preventDefault();

    let email = e.target.elements.email?.value;
    let password = e.target.elements.password?.value;

    axios
      .post(`/login`, { email, password })
      .then((response) => {
        if (response.data.token) {
          sessionStorage.setItem("token", JSON.stringify(response.data.token));
          sessionStorage.setItem("user", JSON.stringify(response.data.user));

          history.replace("/businesses-request");
        }
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else if (error.request) {
        } else {
        }
      });
  };

  return (
    <div className="justify-center h-screen items-center w-full flex bg-gray-bg1">
      <div className="w-full justify-center  max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16">
        <h1 className="text-2xl font-medium text-primary mt-4 mb-5 text-center">
          Accéder à votre compte 🔐
        </h1>
        <div className="grid ">
          {" "}
          <span className="text-sm w-full justify-center items-center text-red-500 text-center mb-8">
            {error}
          </span>
        </div>
        <form onSubmit={handleFormSubmit}>
          {" "}
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
              id="email"
              placeholder="Votre Email"
            />
          </div>
          <div>
            <label htmlFor="password">Mot de passe</label>
            <input
              type="password"
              className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
              id="password"
              placeholder="Votre mot de passe"
            />
          </div>
          <div className="flex justify-center items-center mt-6">
            <button
              className={`bg-blue-500 py-2 px-4 text-sm text-white rounded border border-green focus:outline-none`}
            >
              Se connecter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
