import React from "react";
import axios from "axios";
import Loader from "../components/Loader";

import { useEffect, useState } from "react";
import { useParams } from "react-router";

import profileImg from "../assets/img/profile.png";

import Button from "@material-tailwind/react/Button";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";

import Orders from "components/Orders";
import DoughnutChart from "components/DoughnutChart";
import Gains from "components/Gains";
import Meals from "components/Meals";
import PageTitle from "./../components/PageTitle";
import { getToken } from "auth";
import MonthlyProfit from "components/MonthlyProfit";
import { sortByYearAndMonth } from "helper";

function BusinessDetails() {
  const [meals, setMeals] = useState([]);
  const [business, setBusiness] = useState([]);
  const [orders, setOrders] = useState({});
  const [ordersStatus, setOrdersStatus] = useState({});
  const [percentage, setPercentage] = useState(0);

  const [load, setload] = useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [showReset, setShowReset] = React.useState(false);
  const { id } = useParams();

  //load data from the server
  useEffect(() => {
    try {
      getBusiness();
      getOrders();
      getOrdersStatus();
      // setNewPrice(orders.total * business.data.percentage);
      axios
        .get(`/meals/${id}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((res) => {
          const data = res.data;

          setMeals(data);

          setload(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  }, []);
  const getBusiness = async () => {
    await axios
      .get(`/businesses/${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        const data = res.data;

        setBusiness(data);
        setPercentage(data.percentage);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getOrders = async () => {
    await axios
      .get(`/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        const data = res.data;

        setOrders(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOrdersStatus = async () => {
    await axios
      .get(`/orders/${id}/orderStatus`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        const data = res.data;

        setOrdersStatus(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChange = (e) => {
    const value = e.target.value;

    let percentage;
    if (value < 1 && value >= 0)
      try {
        percentage = parseFloat(value);

        setPercentage(percentage?.toFixed(2));
      } catch (error) {}
    else {
      alert("Please enter a valid data!");
    }
  };
  //handle activate business
  const handleSetPercentage = (id) => {
    axios
      .patch(
        `/businesses/${id}`,
        { percentage: percentage },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((res, err) => {
        if (!err) alert("Le Pourcentage a été modifie ");
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // handle reset orders
  const handleReset = (id) => {
    console.log(id);
    axios
      .patch(
        `/orders/businesses/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((res, err) => {
        if (!err) alert("  les compteurs de gains sont rénitialiser ");
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let formatedTotalPrice = 0;
  let formatedNewPrice = 0;
  let formatedPercentage = 0;
  try {
    formatedTotalPrice = parseFloat(orders.total).toFixed(2);
    formatedNewPrice = parseFloat(
      orders.total * business.data.percentage
    ).toFixed(2);
    formatedPercentage = parseFloat(business.data.percentage).toFixed(2);
  } catch (error) {}
  let newData = sortByYearAndMonth(orders?.orders);

  return load ? (
    <Loader />
  ) : (
    <div className="p-10">
      <PageTitle>Détails de l'entreprise</PageTitle>
      <Modal size="sm" active={showModal} toggler={() => setShowModal(false)}>
        <ModalHeader toggler={() => setShowModal(false)}>
          Modifier le pourcentage
        </ModalHeader>
        <ModalBody>
          <form>
            <span>Entrez une valeur entre 0 et 1 </span>
            <input
              type="number"
              className="px-2 py-1 placeholder-gray-400 text-gray-600 relative bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring w-full"
              value={percentage}
              onChange={handleChange}
              max="1"
              min="0"
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => setShowModal(false)}
            ripple="dark"
          >
            Fermer
          </Button>

          <Button
            color="green"
            onClick={(e) => {
              setShowModal(false);
              handleSetPercentage(business.id);
            }}
            ripple="light"
          >
            Enregistrer
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" active={showReset} toggler={() => setShowReset(false)}>
        <ModalHeader toggler={() => setShowReset(false)}>
          Rénitialiser les compteurs de gains ?
        </ModalHeader>

        <ModalFooter>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => setShowReset(false)}
            ripple="dark"
          >
            Fermer
          </Button>

          <Button
            color="green"
            onClick={(e) => {
              setShowReset(false);
              handleReset(business.id);
            }}
            ripple="light"
          >
            Rénitialiser
          </Button>
        </ModalFooter>
      </Modal>
      <div className="grid grid-cols-2 gap-3  items-stretch mb-5 ">
        <Gains
          formatedNewPrice={formatedNewPrice}
          business={business}
          profileImg={profileImg}
          formatedTotalPrice={formatedTotalPrice}
          formatedPercentage={formatedPercentage}
          setShowModal={setShowModal}
          setShowReset={setShowReset}
        />
        <div className="col-span-1">
          <DoughnutChart ordersStatus={ordersStatus} />
        </div>
      </div>
      <Meals data={meals} />
      <Orders data={orders?.orders} />
      <MonthlyProfit
        data={newData}
        percentage={formatedPercentage}
        showData={orders?.orders?.length > 0}
      />
    </div>
  );
}

export default BusinessDetails;
