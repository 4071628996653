import { useState } from "react";
import { NavLink } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import Icon from "@material-tailwind/react/Icon";
import H6 from "@material-tailwind/react/Heading6";
import { logout } from "helper";

export default function Sidebar() {
  const [showSidebar, setShowSidebar] = useState("-left-64");

  return (
    <>
      <AdminNavbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div
        className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
      >
        <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
          <span className="mt-2 text-center w-full inline-block">
            <H6 color="gray">Bnin w Rkhis</H6>
            <h2 className="text-sm text-gray-800 font-medium">
              Bienvenue {JSON.parse(sessionStorage.getItem("user")).name} !
            </h2>
          </span>
          <div className="flex flex-col">
            <hr className="my-4 min-w-full" />

            <ul className="flex-col min-w-full flex list-none">
              <li className="rounded-lg mb-4">
                <NavLink
                  to="/businesses-request"
                  exact
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="toc" size="2xl" />
                  Demande des entreprises
                </NavLink>
              </li>
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/validated-businesses"
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="toc" size="2xl" />
                  Entreprises validées
                </NavLink>
              </li>
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/clients-manager"
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="toc" size="2xl" />
                  Gestion des clients
                </NavLink>
              </li>
              <li className="rounded-lg mb-2">
                <span
                  onClick={() => logout()}
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg cursor-pointer"
                >
                  <Icon name="account_circle" size="2xl" />
                  Déconnexion
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
