import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import ItemTitle from "./ItemTitle";
import MealsTable from "./MealsTable";
function Meals({ data }) {
  const [paginateConfig, setPaginateConfig] = useState({
    perPage: 5,
    page: 0,
    pages: 0,
  });
  useEffect(() => {
    try {
      setPaginateConfig({
        ...paginateConfig,
        pages: Math.ceil(data?.length / paginateConfig.perPage),
      });
    } catch (error) {}
  }, [data]);
  const handlePageClick = (event) => {
    let page = event.selected;

    setPaginateConfig({ ...paginateConfig, page });
  };
  const { page, perPage, pages } = paginateConfig;
  let items = data?.slice(page * perPage, (page + 1) * perPage);
  return (
    <div>
      <h1 className=" text-2xl  mb-5">Repas</h1>
      {data?.length === 0 ? (
        <h2 className="text-center text-2xl ">Pas des repas</h2>
      ) : (
        <>
          <div className="mt-5 mb-3">
            <ItemTitle title={"Nombre Des Repas :"} value={data?.length} />
          </div>
          <MealsTable data={items} />
          <ReactPaginate
            previousLabel={"précédent"}
            nextLabel={"suivant"}
            pageCount={pages}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </>
      )}
    </div>
  );
}

export default Meals;
