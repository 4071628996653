import _ from "underscore";
export const getDataByMonth = (data, month, year) => {
  const newData = data?.filter((i) => {
    const ts_ms = i.data.timeOrder._seconds * 1000;
    const date_ob = new Date(ts_ms);
    // year as 4 digits (YYYY)
    const DataYear = date_ob.getFullYear() + "";
    // console.log(DataYear, year);

    // month as 2 digits (MM)
    const dataMonth = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    // console.log(dataMonth, month);
    return dataMonth === month && DataYear === year;
  });
  return newData;
};

export const sortByYearAndMonth = (data = []) => {
  var sorted = data?.sort(function (a, b) {
    const ts_ms = a.data.timeOrder._seconds * 1000;
    const d1 = new Date(ts_ms);
    const ts_ms2 = b.data.timeOrder._seconds * 1000;
    const d2 = new Date(ts_ms2);

    return new Date(d1) - new Date(d2);
  });

  var byYearAndByMonth = {};

  _.each(sorted, function (item) {
    const ts_ms = item.data.timeOrder._seconds * 1000;
    const d1 = new Date(ts_ms);
    const year = d1.getFullYear();
    const month = ("0" + (d1.getMonth() + 1)).slice(-2);

    if (typeof byYearAndByMonth[year] === "undefined") {
      byYearAndByMonth[year] = {};
    }

    if (typeof byYearAndByMonth[year][month] === "undefined") {
      byYearAndByMonth[year][month] = [];
    }

    byYearAndByMonth[year][month].push(item);
  });
  return byYearAndByMonth;

  // Object.entries(byYearAndByMonth).forEach(([key, value]) => {
  //   Object.entries(value).forEach(([key, value]) => {
  //     console.log(value);
  //   });
  // });
  //byYearAndByMonth[2021]["01"].map((i) => console.log(i.data.totalPrice));

  // console.log(sum);
};

export const sum = (data = []) => {
  let sum = 0;
  data.forEach((i) => {
    sum += i.data.totalPrice;
  });
  return sum;
};
export const getMonth = (month) => {
  switch (month) {
    case "01":
      return "janvier";
    case "02":
      return "février";
    case "03":
      return "mars";
    case "04":
      return "avril";
    case "05":
      return "mai";
    case "06":
      return "juin";
    case "07":
      return "juillet";
    case "08":
      return "aout";
    case "09":
      return "septembre";
    case "10":
      return "octobre";
    case "11":
      return "novembre";
    case "12":
      return "décembre";

    default:
      return "";
  }
};

export const getTitle = (title) => {
  if (title.startsWith("VALIDATED-BUSINESSES/"))
    return "Détails de l'entreprise";
  else
    switch (title) {
      case "BUSINESSES-REQUEST":
        return "Demande des entreprises";
      case "VALIDATED-BUSINESSES":
        return "Entreprises validées";
      case "CLIENTS-MANAGER":
        return "Gestion des clients";

      default:
        return title;
    }
};

export const logout = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
  window.location.href = "/login";
};
