import React from "react";
import { Link } from "react-router-dom";
import profileImg from "../assets/img/profile.png";

function Table({
  data = [],
  action,
  handleAction,
  validated,

  setShowDocs,
  setDocs,
}) {
  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      N° de téléphone
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Adresse
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Documents
                    </th>
                    {validated && (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        détails
                      </th>
                    )}
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.map((business) => (
                    <tr key={business.data.email + business.data.phoneNumber}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={business.data.imageLink || profileImg}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {business.data.businessName}
                            </div>
                            <div className="text-sm text-gray-500">
                              {business.data.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {business.data.email}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {business.data.phoneNumber}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {business.data.writtenAddress}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {business.data.cardIdFileLink ? (
                          <span
                            onClick={() => {
                              setDocs({
                                cardIdFileLink: business.data.cardIdFileLink,
                                commercialRegisterFileLink:
                                  business.data.commercialRegisterFileLink,
                                nifFileLink: business.data.nifFileLink,
                              });
                              setShowDocs(true);
                            }}
                            className="text-blue-500 cursor-pointer"
                          >
                            voir documents
                          </span>
                        ) : (
                          <span>Pas de documents</span>
                        )}
                      </td>
                      {validated && (
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-500">
                          <Link to={`/validated-businesses/${business.id}`}>
                            Détails
                          </Link>
                        </td>
                      )}
                      <td
                        onClick={() => handleAction(business.id)}
                        className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium "
                      >
                        {action}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}

export default Table;
