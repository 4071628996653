import { Switch, Route, Redirect } from "react-router-dom";
import Sidebar from "components/Sidebar";

// import Footer from "components/Footer";

// Tailwind CSS Style Sheet
import "assets/styles/tailwind.css";
import BusinessesRequest from "./pages/BusinessesRequest";
import ValidatedBusinesses from "./pages/ValidatedBusinesses";
import ClientsManager from "./pages/ClientManager";
import BusinessDetails from "./pages/BusinessDetails";
import Login from "./pages/Login";
import { getToken } from "auth";
import { useState } from "react";

function App() {
  const [refresh, setRefresh] = useState(false);

  const authGuard = (Component) => (props) => {
    return getToken() ? (
      <Component {...props} setRefresh={setRefresh} />
    ) : (
      <Redirect to="/login" />
    );
  };
  return (
    <>
      {getToken() && <Sidebar />}

      <div className={getToken() ? "md:ml-64 mb-24" : ""}>
        <Switch>
          <Route
            exact
            path="/businesses-request"
            render={authGuard(BusinessesRequest)}
          />
          <Route
            exact
            path="/validated-Businesses/:id"
            render={authGuard(BusinessDetails)}
          />
          <Route
            exact
            path="/validated-Businesses"
            render={authGuard(ValidatedBusinesses)}
          />
          <Route
            exact
            path="/clients-manager"
            render={authGuard(ClientsManager)}
          />

          {getToken() ? (
            <Redirect from="/login" to="/businesses-request" />
          ) : null}
          <Route exact path="/login" component={Login} />

          <Redirect from="*" to="/businesses-request" />
        </Switch>
      </div>
      {/*<Footer />*/}
    </>
  );
}

export default App;
