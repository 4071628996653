import jwtDecode from "jwt-decode";
export const getToken = () => {
  try {
    const token = JSON.parse(sessionStorage.getItem("token"));
    if (token) {
      const { exp } = jwtDecode(token);
      const expirationTime = exp * 1000;

      if (Date.now() >= expirationTime) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user");
        return null;
      } else return token;
    } else {
      return null;
    }
  } catch (error) {}
};
