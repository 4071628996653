import React from "react";

function ItemTitle({ title, value }) {
  return (
    <>
      <span className="text-gray-800 font-medium">{title}</span>
      <span className="px-2 py-1  ml-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {value}
      </span>
    </>
  );
}

export default ItemTitle;
