import { sortByYearAndMonth } from "helper";

import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import ItemTitle from "./ItemTitle";
import MonthlyProfit from "./MonthlyProfit";
import OrderTable from "./OrdersTable";

function Orders({ data }) {
  const [paginateConfig, setPaginateConfig] = useState({
    perPage: 5,
    page: 0,
    pages: 0,
  });

  const handlePageClick = (event) => {
    let page = event.selected;

    setPaginateConfig({ ...paginateConfig, page });
  };
  const { page, perPage = 5, pages } = paginateConfig;
  let items = data?.slice(page * perPage, (page + 1) * perPage);
  useEffect(() => {
    try {
      setPaginateConfig({
        ...paginateConfig,
        pages: Math.ceil(data?.length / paginateConfig.perPage),
      });
    } catch (error) {}
  }, [data]);

  return (
    <div className="mt-5">
      <h1 className=" text-2xl  mb-5">Commandes</h1>
      {data?.length === 0 ? (
        <h2 className="text-center text-2xl ">Pas des commandes</h2>
      ) : (
        <>
          <div className="mt-5 mb-3">
            <ItemTitle title=" Nombre Des Commande :" value={data?.length} />
          </div>

          <OrderTable data={items} />
          <ReactPaginate
            previousLabel={"précédent"}
            nextLabel={"suivant"}
            pageCount={pages}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </>
      )}
    </div>
  );
}

export default Orders;
