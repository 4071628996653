import React from "react";
import DocLink from "./DocLink";

function Docs({ nifFileLink, commercialRegisterFileLink, cardIdFileLink }) {
  return (
    <div className="flex flex-col md:flex-row w-full  gap-3 ">
      <DocLink label={"NIF"} link={nifFileLink} />
      <DocLink
        label={"Registre de commerce"}
        link={commercialRegisterFileLink}
      />
      <DocLink label={"Carte d'identité"} link={cardIdFileLink} />
    </div>
  );
}

export default Docs;
