import React from "react";
import ItemTitle from "./ItemTitle";

function Gains({
  business = {},
  profileImg,
  formatedTotalPrice = 0,
  formatedPercentage = 0,
  formatedNewPrice = 0,
  setShowModal,
  setShowReset,
}) {
  return (
    <div className="col-span-1 bg-white p-5 rounded-lg ">
      <div className=" py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            {business.data?.imageLink && (
              <img
                className="h-10 w-10 rounded-full"
                src={business.data?.imageLink || profileImg}
                alt=""
              />
            )}
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {business.data?.businessName}
            </div>
            <div className="text-sm text-gray-500">{business.data?.email}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 mb-5 mt-3">
        <div className="mt-2 ">
          <ItemTitle
            title="Gains total de restaurant :"
            value={`${formatedTotalPrice} DA`}
          />
        </div>
        <div className="mt-2">
          <ItemTitle title="Votre gains :" value={`${formatedNewPrice} DA`} />
        </div>
        <div className="flex mt-2 gap-3">
          <ItemTitle title="Pourcentage :" value={`${formatedPercentage} `} />

          <span
            onClick={(e) => setShowModal(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-medium  cursor-pointer  px-4  rounded-md"
          >
            Modifier
          </span>
        </div>
        <div className="flex mt-3 gap-3">
          <span
            onClick={(e) => setShowReset(true)}
            className="bg-red-500 hover:bg-red-700 text-white font-medium  cursor-pointer  px-4  rounded-md"
          >
            Rénitialiser les gains
          </span>
        </div>
      </div>
    </div>
  );
}

export default Gains;
