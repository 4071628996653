import React from "react";

function DocLink({ link, label }) {
  return (
    <div
      className={`cursor-pointer bg-gray-100 rounded-full px-3 py-1 text-gray-800 font-medium ${
        link?.length > 5 ? "" : "opacity-50 cursor-not-allowed"
      }`}
    >
      <a href={link} target="_blank" rel="noreferrer">
        {label}
      </a>
    </div>
  );
}

export default DocLink;
